@import url(https://fonts.googleapis.com/css?family=Capriola&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800);
@import "/node_modules/bootstrap-scss/bootstrap.scss";
@import "/node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css";
@import "/node_modules/ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css";         
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
// @import "~swiper/css/effect-cards";
@import "~animate-scss/animate.css";
@import 'color-8';
@import "font-awesome.scss";
@import "themify.scss";
@import "flaticon.scss";
@import "../css/keyframes.css";
@import "~animate.css/animate.min.css";
@import "/node_modules/ngx-toastr/toastr.css";
