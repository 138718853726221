@import 'theme/variable' ;
$primary: #fb3b64;
$secondary: #071828;
$light: #071828;
$dark:#fb3b64;
@import 'theme/style';
@import 'theme/responsive';
@import 'theme/customize';
@import 'theme/theme-dark';
@import 'theme/theme-rtl';
