/* You can add global styles to this file, and also import other style files */

.owl-item {
    &.active {
      &.center {
        .price-box:hover {
            -ms-transform: scale(1.2) !important;
            -moz-transform: scale(1.2)!important;
            -webkit-transform: scale(1.2)!important;
            -o-transform: scale(1.2)!important;
            transform: scale(1.2)!important;
          }
        }
      }
    }
  

  